import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { AppRoute } from '../config/route-config';
import { UserProvider } from '../context/user/UserProvider';
import { PermissionsProvider } from '../context/permission/PermissionsProvider';
import { UserFetcher } from '../components/UserFetcher/UserFetcher';
import { Placeholder1 } from '../components/PlaceHolder1/PlaceHolder1';
import { Placeholder2 } from '../components/PlaceHolder2/PlaceHolder2';

export const routesTestId = 'routesTestId';

export const Routes = () => (
  <UserProvider>
    <PermissionsProvider>
      <UserFetcher />
      <div data-testid={routesTestId}>
        <Switch>
          <Route exact path={AppRoute.Index}>
            <Redirect to={AppRoute.Placeholder1} />
          </Route>
          <Route exact path={AppRoute.Placeholder1}>
            <Placeholder1 />
          </Route>
          <Route exact path={AppRoute.Placeholder2}>
            <Placeholder2 />
          </Route>
        </Switch>
      </div>
    </PermissionsProvider>
  </UserProvider>
);
